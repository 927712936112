/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const theme = {
  colors: {
    background: `#000000`,
    color: '#FFFFFF',
    footerBg: `transparent`,
    headerActiveBg: `#000000`,
    headerActiveColor: `#FFFFFF`,
    highlight: `#FFFFFF`,
    heading: `#FFFFFF`,
    text: `#FFFFFF`,
    article: `#FFFFFF`,
    mute: `#FFFFFF`,
    omegaDarker: `#FFFFFF`,
    alpha: '#006CFF',
    contentBg: `#000000`
  }

}

export default theme
